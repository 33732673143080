<script setup lang="ts">
import { PhoneIcon, SmsIcon } from '@cfgtech/icons'
import { CfgButton, CfgTypography, CfgTypographySizes } from '@cfgtech/ui'

defineProps<{
  buttonStroke?: boolean
  title: string
}>()

const emit = defineEmits<{
  'leadDialogOpened': []
  'phone:click': []
  'email:click': []
  'message:click': []
}>()

// const responsive = useResponsive()

const openLeadDialog = ref(false)

function onOpenLeadDialogClick() {
  openLeadDialog.value = true

  emit('leadDialogOpened')
}

// const phone = defaultManager.phone
// const phoneComponent = computed(
//   () => {
//     const tag = responsive.value.mobile ? 'a' : 'span'
//     return {
//       tag,
//       attributes: tag === 'a'
//         ? {
//             href: `tel:${defaultManager.phone.replace(/ /g, '')}`,
//             class: 'text-brand underline',
//           }
//         : {},
//     }
//   },
// )
</script>

<template>
  <div class="space-y-4">
    <div>
      <CfgTypography :size="CfgTypographySizes.h3" tag-name="h2">
        {{ title }}
      </CfgTypography>
    </div>

    <ContactManager />

    <div class="flex flex-col items-center justify-center gap-2">
      <CfgButton
        class="w-full py-4 lg:py-2.5"
        flat
        icon-placement="left"
        :label="$t('contact.card.ctaFree')"
        :stroke="buttonStroke"
        variant="primary"
        @click="onOpenLeadDialogClick"
      >
        <template #icon>
          <PhoneIcon
            class="text-[20px]"
            :class="{
              'text-white': !buttonStroke,
            }"
          />
        </template>
      </CfgButton>

      <CfgButton
        class="w-full py-4 lg:py-2.5"
        flat
        icon-placement="left"
        :label="$t('contact.card.message')"
        stroke
        variant="primary"
        @click="$openSmartsuppDialog(); $emit('message:click')"
      >
        <template #icon>
          <SmsIcon class="text-[20px]" />
        </template>
      </CfgButton>
    </div>
  </div>

  <ClientOnly>
    <LazyIssueDetailsHeroSectionLeadDialog v-model="openLeadDialog" />
  </ClientOnly>
</template>
